body {
    font-size: 1rem;
}

.navbar-brand {
    font-size: 1.25rem;
}

.row {
    margin-right: 0;
    margin-left: 0;
}

pre code {
    background-color: #f1f1f1;
    border-radius: 3px;
    display: block;
    padding: 3px 5px;
    margin: 0 3px;
    box-sizing: border-box;
    border: 1px solid #ccc;
}

.form-group {
    margin-bottom: 1rem;
}

.card-title {
    margin-top: var(--bs-card-title-spacer-y);
}

a {
    text-decoration: none;
}

img {
    max-width: 100%;
}
